import { useState, useEffect, createRef } from "react";
import {
  Grid,
  Button,
  Typography,
  Backdrop,
  CircularProgress,
  Box,
  Stepper,
  Step,
  StepLabel,
  Modal,
} from "@mui/material";
import Shell from "components/shell";
import { SubmitButton } from "components/buttons";
import { formatPhoneNumber, formatDate } from "utilities";
import classes from "./index.module.css";
import copy from "constants/copy";
import states from "constants/states";
import { isFormValid, Input, getFormValues } from "components/controls";
import { nameValidator } from "utilities";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  updateRegistration,
  updateDependentInvite,
  updateDependentEnrolment,
} from "../../data";
import { makeStyles } from "@mui/styles";
import { ErrorModal } from "components/errorModal";
import {
  SUBJECT_EMPLOYEE,
  SUBJECT_DEPENDENT,
  SPONSOR_HP,
  SPONSOR_CVS,
  NO_USER_FOUND,
} from "../../constants/app";

const subjects = ["employee", "provider", "dependent"];

const steps = ["Welcome!", "Enrollment Information"];

const useStyles = makeStyles({
  root: {
    "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": {
      color: "#174E8C",
    },
    "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {
      color: "#174E8C",
    },
    "& .css-vnkopk-MuiStepLabel-iconContainer": {
      background: "white",
      borderRadius: "50%",
      padding: "5px",
    },
  },
});

const Register = () => {
  const styles = useStyles();
  let [subject, setSubject] = useState(useParams().subject);
  const [registrationValues, updateRegistrationValues] = useState({});
  const [errors, updateErrors] = useState([]);
  const [dependentEmails, updateDependentEmails] = useState([]);
  const [submitable, updateSubmitable] = useState(false);
  const [loading, updateLoading] = useState(false);
  const [credentialsErros, setCredentialsErros] = useState();
  const [open, setOpen] = useState({ open: false, target: null });
  const handleOpen = (data) => setOpen({ open: true, target: data });
  const handleClose = () => setOpen({ open: false });
  const formRef = createRef();
  let { code } = useParams();
  let sponsor = useParams().sponsor.toUpperCase();
  const navigate = useNavigate();

  // if(!subjects.includes(subject)) subject = subjects[0];

  const messages = {
    title: copy(`enrollment.title.${subject}`, ["BOLD Capital Partners"]),
    subtitle: copy(`enrollment.subtitle.${subject}`),
    formTitle: copy(`enrollment.form.title.${subject}`),
    formSubtitle: copy(`enrollment.form.subtitle.${subject}`, null, ""),
    allFieldsRequired: copy(`enrollment.form.allFieldsRequired.${subject}`),
    firstNameLabel: copy(`enrollment.form.firstName.${subject}`),
    lastNameLabel: copy(`enrollment.form.lastName.${subject}`),
    dateOfBirthLabel: copy(`enrollment.form.dateOfBirth.${subject}`),
    emailLabel: copy(`enrollment.form.email.${subject}`),
    dependentEmailLabel: copy(`enrollment.form.dependentEmail.${subject}`, "1"),
    phoneLabel: copy(`enrollment.form.phone.${subject}`),
    stateLabel: copy(`enrollment.form.state.${subject}`),
    consent: copy(`enrollment.form.consent.${subject}`),
    marketing: copy(`enrollment.form.marketing.${subject}`),
    atrial: copy(`enrollment.form.atrial.${subject}`),
    hypertension: copy(`enrollment.form.hypertension.${subject}`),
    dependentButton: copy(`enrollment.dependentButton.${subject}`),
    submitButton: copy(`enrollment.form.submitButton.${subject}`),
    enrollButton: copy(`enrollment.form.enrollButton.${subject}`),
    eligibilityTitle: copy(`enrollment.eligibility.title.${subject}`),
    diseaseTitle: copy(`enrollment.eligibility.diseaseTitle.${subject}`),
    diseaseDescription: copy(
      `enrollment.eligibility.diseaseDescription.${subject}`
    ),
    failureTitle: copy(`enrollment.eligibility.failureTitle.${subject}`),
    failureDescription: copy(
      `enrollment.eligibility.failureDescription.${subject}`
    ),
    surgeryTitle: copy(`enrollment.eligibility.surgeryTitle.${subject}`),
    surgeryDescription: copy(
      `enrollment.eligibility.surgeryDescription.${subject}`
    ),
    restrictionTitle: copy(
      `enrollment.eligibility.restrictionTitle.${subject}`
    ),
    restrictionDescription: copy(
      `enrollment.eligibility.restrictionDescription.${subject}`
    ),
    dialysisTitle: copy(`enrollment.eligibility.dialysisTitle.${subject}`),
    dialysisDescription: copy(
      `enrollment.eligibility.dialysisDescription.${subject}`
    ),
  };

  const handleRegistrationChange = (e) => {
    const target = e.target;
    let newValue = target.value;
    const name = target.name;

    if (name === "phoneNumber") newValue = formatPhoneNumber(newValue);
    else if (name === "dateOfBirth" || name === "employeeDateOfBirth")
      newValue = formatDate(newValue);
    else if (name === "firstName" || name === "enroleeFirstName")
      newValue = nameValidator(newValue);
    else if (name === "lastName" || name === "enroleeLastName")
      newValue = nameValidator(newValue);

    if (target.type === "checkbox") newValue = target.checked;
    updateSubmitable(isFormValid(formRef.current));
    updateRegistrationValues({ ...registrationValues, [name]: newValue });
    updateErrors([]);
  };
  const fieldConfigs = {
    employeeFirstName: {
      type: "text",
      label: `${
        sponsor === SPONSOR_HP && subject === SUBJECT_DEPENDENT && "Employee"
      } ${messages.firstNameLabel}`,
      value: registrationValues.employeeFirstName,
      name: "employeeFirstName",
      onChange: handleRegistrationChange,
      validation: ["string"],
    },
    employeeLastName: {
      type: "text",
      label: `${
        sponsor === SPONSOR_HP && subject === SUBJECT_DEPENDENT && "Employee"
      } ${messages.lastNameLabel}`,
      value: registrationValues.employeeLastName,
      name: "employeeLastName",
      onChange: handleRegistrationChange,
      validation: ["string"],
    },
    employeeDateOfBirth: {
      type: "text",
      label: `${
        sponsor === SPONSOR_HP && subject === SUBJECT_DEPENDENT && "Employee"
      } ${messages.dateOfBirthLabel}`,
      value: registrationValues.employeeDateOfBirth,
      name: "employeeDateOfBirth",
      onChange: handleRegistrationChange,
      placeholder: "mm/dd/yyyy",
      validation: ["date"],
    },
    firstName: {
      type: "text",
      label: messages.firstNameLabel,
      value: registrationValues.firstName,
      name: "firstName",
      onChange: handleRegistrationChange,
      validation: ["string"],
      credentialValidation: credentialsErros?.first_name_mismatch,
    },
    lastName: {
      type: "text",
      label: messages.lastNameLabel,
      value: registrationValues.lastName,
      name: "lastName",
      onChange: handleRegistrationChange,
      validation: ["string"],
      credentialValidation: credentialsErros?.last_name_mismatch,
    },
    dateOfBirth: {
      type: "text",
      label: messages.dateOfBirthLabel,
      value: registrationValues.dateOfBirth,
      name: "dateOfBirth",
      onChange: handleRegistrationChange,
      placeholder: "mm/dd/yyyy",
      validation: ["date"],
      credentialValidation: credentialsErros?.dob_mismatch,
    },
    email: {
      type: "email",
      label: messages.emailLabel,
      value: registrationValues.email,
      name: "email",
      onChange: handleRegistrationChange,
      validation: ["email"],
    },
    phone: {
      type: "text",
      label: messages.phoneLabel,
      value: registrationValues.phoneNumber,
      name: "phoneNumber",
      onChange: handleRegistrationChange,
      validation: ["phone"],
    },
    state: {
      type: "select",
      label: messages.stateLabel,
      value: registrationValues.state,
      name: "state",
      onChange: handleRegistrationChange,
      validation: ["selected"],
    },
    consent: {
      type: "checkbox",
      label: messages.consent,
      value: registrationValues.consent,
      name: "consentTosPp",
      onChange: handleRegistrationChange,
      validation: ["checked"],
    },
    marketing: {
      type: "checkbox",
      label: messages.marketing,
      value: registrationValues.marketing,
      name: "consentMarketing",
      onChange: handleRegistrationChange,
    },
    atrial: {
      type: "checkbox",
      label: messages.atrial,
      value: registrationValues.atrial,
      name: "atrialFibrillation",
      onChange: handleRegistrationChange,
    },
    hypertension: {
      type: "checkbox",
      label: messages.hypertension,
      value: registrationValues.hypertension,
      name: "hypertensionBox",
      onChange: handleRegistrationChange,
    },
  };
  if (sponsor === SPONSOR_CVS) {
    fieldConfigs["atrial"]["validation"] = ["atrial"];
  }
  const [canAddMoreDependent, setCanAddMoreDependent] = useState(true);
  const addDependentEmail = () => {
    const emails = dependentEmails.slice();
    const index = dependentEmails.length + 1;
    const emailConfig = {
      label: copy(`enrollment.form.dependentEmail.${subject}`, [index]),
      name: `dependentEmail${index}`,
      type: "email",
      index,
      validation: ["email"],
    };
    emails.push(emailConfig);
    if (emails.length > 2) {
      setCanAddMoreDependent(false);
    }
    updateDependentEmails(emails);
    updateSubmitable(isFormValid(formRef.current));
  };

  const removeDependentEmail = function (i) {
    const emails = dependentEmails.slice();
    // emails.splice(i, 1);
    emails.pop();
    if (emails.length < 3) {
      setCanAddMoreDependent(true);
    }
    updateDependentEmails(emails);
  };
  const getUpdatedformValue = (formValues) => {
    let programType = `${formValues?.atrialFibrillation}-${formValues?.hypertensionBox}`;
    const object = {
      "false-false": "noneButInterested",
      "true-false": "Afib-Id",
      "false-true": "Hypertension-Id",
      "true-true": "AfibAndHypertension-Id",
    };
    formValues["programId"] = object[programType];
    formValues["sponsorSymbol"] = formValues.sponsorSymbol.toUpperCase();
    delete formValues.hypertensionBox;
    delete formValues.atrialFibrillation;
    return formValues;
  };
  const getError = (errors) => {
    let errorObj = {
      first_name_mismatch: null,
      dob_mismatch: null,
      last_name_mismatch: null,
    };
    for (let i = 0; i < errors.length; i++) {
      let code = errors[i]?.code;
      let errorDetail = errors[i]?.detail;
      if (code in errorObj) {
        errorObj[code] = errors[i]?.detail;
      } else if (errorDetail === NO_USER_FOUND) {
        handleOpen(SPONSOR_CVS);
      }
    }
    setCredentialsErros(errorObj);
  };
  useEffect(() => {
    updateRegistrationValues({});
  }, [subject]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid(e.target)) {
      const formValues = getFormValues(e.target);
      formValues.sponsorSymbol = formValues.sponsorSymbol.toUpperCase();
      console.log(formValues);
      let resp;
      let respCode;
      updateLoading(true);
      if (subject === "provider") {
        resp = await updateDependentInvite(formValues);
      } else if (subject === SUBJECT_EMPLOYEE) {
        let updatedFormValue = getUpdatedformValue(formValues);
        resp = await updateRegistration(updatedFormValue);
        if (resp?.errors) {
          if (sponsor === SPONSOR_CVS) {
            getError(resp?.errors);
          } else handleOpen(SPONSOR_HP);
        }
      } else {
        resp = await updateDependentEnrolment(formValues);
        if (resp?.status !== 200) {
          updateLoading(false);
          handleOpen(SPONSOR_HP);
        }
        resp = await resp.json();
      }
      if (resp.errors) updateErrors(resp.errors);
      else navigate(`/confirmation/${sponsor}/${subject}`);
      updateLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    sponsor === SPONSOR_CVS &&
      subject === SUBJECT_DEPENDENT &&
      navigate(`/enroll/${SPONSOR_CVS}/${SUBJECT_EMPLOYEE}`);
    subject === "provider" && addDependentEmail();
  }, []);
  useEffect(
    () => updateSubmitable(isFormValid(formRef.current)),
    [dependentEmails]
  );
  const checkSubmit = () => {
    let check =
      registrationValues?.atrialFibrillation === true ||
      registrationValues?.hypertensionBox === true;
    return check;
  };
  const getEnrollInfo = () => {
    return (
      <>
        <Grid item xs={6} className={classes["form-row"]}>
          <Input {...fieldConfigs.firstName} />
        </Grid>
        <Grid item xs={6} className={classes["form-row"]}>
          <Input {...fieldConfigs.lastName} />
        </Grid>
        <Grid item xs={12} className={classes["form-row"]}>
          <Input {...fieldConfigs.dateOfBirth} />
        </Grid>
      </>
    );
  };
  const getEmployeeInfo = () => {
    return (
      <>
        <Grid item xs={6} className={classes["form-row"]}>
          <Input {...fieldConfigs.employeeFirstName} />
        </Grid>
        <Grid item xs={6} className={classes["form-row"]}>
          <Input {...fieldConfigs.employeeLastName} />
        </Grid>
        <Grid item xs={12} className={classes["form-row"]}>
          <Input {...fieldConfigs.employeeDateOfBirth} />
        </Grid>
      </>
    );
  };
  return (
    <Shell>
      <Grid container className={classes["gridContainer"]}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8} className={classes.titles}>
          {sponsor === SPONSOR_HP && (
            <Box sx={{ width: "100%" }}>
              <Stepper
                activeStep={1}
                alternativeLabel
                className={`${classes["stepperStyle"]} ${styles.root}`}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel className={classes["textStyle"]}>
                      <Typography
                        variant="subtitle1"
                        style={{
                          paddingTop: label === "Welcome!" && 9,
                          fontSize: 18,
                          fontFamily: "'DM Sans', sans-serif",
                          fontWeight: 500,
                          lineHeight: "inherit",
                        }}
                      >
                        {label}
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          )}
          <div
            className={
              sponsor === SPONSOR_HP
                ? classes["titleEnrollText"]
                : classes["cvsTitleEnrolltext"]
            }
          >
            <Typography
              variant="h4"
              style={{
                color: "#182C3D",
                marginBottom: "8px",
                fontSize: 36,
                fontFamily: "'DM Sans', sans-serif",
              }}
            >
              {messages.title}
            </Typography>
            <Typography variant="subtitle1" className={classes["subheadline"]}>
              {messages.subtitle}
            </Typography>
          </div>
          {sponsor === SPONSOR_HP &&
            (subject === SUBJECT_DEPENDENT || subject === SUBJECT_EMPLOYEE) && (
              <Typography variant="subtitle1" className={classes["enrollLink"]}>
                Are you enrolling as an{" "}
                {subject === SUBJECT_EMPLOYEE
                  ? SUBJECT_DEPENDENT
                  : SUBJECT_EMPLOYEE}
                ?{" "}
                <Link
                  onClick={() =>
                    setSubject(
                      subject === SUBJECT_EMPLOYEE
                        ? SUBJECT_DEPENDENT
                        : SUBJECT_EMPLOYEE
                    )
                  }
                  to={`/enroll/${sponsor}/${
                    subject === SUBJECT_EMPLOYEE
                      ? SUBJECT_DEPENDENT
                      : SUBJECT_EMPLOYEE
                  }`}
                  className={classes["aLink"]}
                >
                  Click here.
                </Link>
              </Typography>
            )}
        </Grid>
        <Grid item xs={2}></Grid>
        {/* <Grid item xs={2} md = {2} lg = {2}></Grid> */}
        <Grid
          sm={8}
          md={8}
          lg={8}
          container
          order={{ xs: 2, md: 1 }}
          className={classes["backdrop-container"]}
        >
          <Backdrop open={loading} className={classes.backdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <form
            onSubmit={handleSubmit}
            className={classes["registration-form"]}
            ref={formRef}
          >
            <input
              type="hidden"
              name="sponsorSymbol"
              value={sponsor}
              data-valid="true"
            />
            {subject === SUBJECT_DEPENDENT && (
              <input type="hidden" name="id" value={code} data-valid="true" />
            )}
            <Typography
              variant="h3"
              className={classes["registration-headline"]}
            >
              {messages.formTitle}{" "}
              <span className={classes["all-fields-required"]}>
                {messages.allFieldsRequired}
              </span>
            </Typography>
            <Typography
              variant="subtitle2"
              style={{
                fontSize: 17,
                fontFamily: "'DM Sans', sans-serif",
                fontWeight: 400,
              }}
            >
              Please enter the required fields as they are written on your
              health insurance card.
            </Typography>
            {/* <div
              className={`${classes["registration-error-display"]} ${
                errors.length ? classes["registration-display-errors"] : ""
              }`}
            >
              {errors.map((error, arr, i) => (
                <Typography variant="h4" key={error.id}>
                  {error.title === "Error Dependent Does Not Exist" ? (
                    <>
                      Could not find your email in the system, please inform
                      employee to invite you
                    </>
                  ) : (
                    <>
                      Invalid user information. Please verify that you’ve
                      entered the correct information.
                    </>
                  )}
                </Typography>
              ))}
            </div> */}
            <Typography
              variant="p"
              className={classes["registration-subtitle"]}
            >
              {messages.formSubtitle}
            </Typography>
            {sponsor === SPONSOR_HP && subject === SUBJECT_DEPENDENT && (
              <Grid container spacing={1} className={classes["form-fields"]}>
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    className={classes["registration-headline"]}
                    style={{ fontSize: 21 }}
                  >
                    Employee Information
                  </Typography>
                </Grid>
                {getEmployeeInfo()}
              </Grid>
            )}
            {(subject === SUBJECT_EMPLOYEE ||
              subject === SUBJECT_DEPENDENT) && (
              <Grid container spacing={1} className={classes["form-fields"]}>
                {sponsor === SPONSOR_HP && subject === SUBJECT_DEPENDENT && (
                  <Grid item xs={12}>
                    <Typography
                      variant="h4"
                      className={classes["registration-headline"]}
                      style={{ fontSize: 21 }}
                    >
                      Enrollee Information
                    </Typography>
                  </Grid>
                )}

                {getEnrollInfo()}
                <Grid item xs={12} className={classes["form-row"]}>
                  <Input {...fieldConfigs.email} />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: "bold",
                      fontSize: 17,
                      fontFamily: "'DM Sans', sans-serif",
                    }}
                  >
                    {sponsor === SPONSOR_CVS &&
                    registrationValues?.atrialFibrillation !== false
                      ? "Please select the statement(s) below that apply to you. To be eligible for KardiaComplete, you should have received an Atrial Fibrillation diagnosis."
                      : `Please select the statement(s) below that apply to you: `}
                    <span className={classes.asterikStyle}> *</span>
                  </Typography>
                  {sponsor === SPONSOR_CVS &&
                    registrationValues?.atrialFibrillation === false && (
                      <div className={classes.errorLine}>
                        This is required field.
                      </div>
                    )}
                  {sponsor === SPONSOR_HP &&
                    ((registrationValues?.atrialFibrillation === false &&
                      registrationValues?.hypertensionBox === undefined) ||
                      (registrationValues?.atrialFibrillation === undefined &&
                        registrationValues?.hypertensionBox === false) ||
                      (registrationValues?.atrialFibrillation === false &&
                        registrationValues?.hypertensionBox === false)) && (
                      <div className={classes.errorLine}>
                        This is required field.
                      </div>
                    )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={`${classes["form-consent"]} ${classes["atrialHypertensionStyle"]}`}
                >
                  <Input {...fieldConfigs.atrial} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={`${classes["form-consent"]} ${classes["atrialHypertensionStyle"]}`}
                >
                  <Input {...fieldConfigs.hypertension} />
                </Grid>
                <Grid item xs={12} className={classes["form-consent"]}>
                  <Input {...fieldConfigs.consent} />
                </Grid>
                <Grid item xs={12} className={classes["form-consent"]}>
                  <Input {...fieldConfigs.marketing} />
                </Grid>
              </Grid>
            )}
            {subject === "provider" && (
              <>
                <Grid container spacing={1} className={classes["form-fields"]}>
                  <Grid item xs={6} className={classes["form-row"]}>
                    <Input {...fieldConfigs.firstName} />
                  </Grid>
                  <Grid item xs={6} className={classes["form-row"]}>
                    <Input {...fieldConfigs.lastName} />
                  </Grid>
                  <Grid item xs={12} className={classes["form-row"]}>
                    <Input {...fieldConfigs.email} />
                  </Grid>
                  {dependentEmails.map((email) => {
                    const config = {
                      ...email,
                      value:
                        registrationValues[`dependentEmail${email.index}`] ||
                        "",
                      onChange: handleRegistrationChange,
                      remove:
                        email.index > 1
                          ? removeDependentEmail.bind(null, email.index - 1)
                          : null,
                    };
                    return (
                      <Grid
                        item
                        xs={12}
                        className={classes["form-row"]}
                        key={email.index}
                      >
                        <Input {...config} />
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes["add-dependent-container"]}
                >
                  <Button
                    disabled={!canAddMoreDependent}
                    variant="outlined"
                    className={classes["invite-outline-button"]}
                    fullWidth={true}
                    onClick={addDependentEmail}
                  >
                    {messages.dependentButton}
                  </Button>
                </Grid>
              </>
            )}
            <Grid item xs={12} className={classes["submit-button-container"]}>
              <SubmitButton
                text={
                  subject === "provider"
                    ? messages.submitButton
                    : messages.enrollButton
                }
                fullWidth={true}
                disabled={
                  sponsor === SPONSOR_HP
                    ? !checkSubmit() || !submitable
                    : !submitable || loading
                }
              />
            </Grid>
          </form>
        </Grid>
        <Grid item xs={2} md={2} lg={2}></Grid>
        {/* <Grid item md={6} xs={12} order={{ xs: 1, md: 2 }}>
          <div className={classes.eligibility}>
            <Typography variant="p" className={classes["eligibility-intro"]}>
              {messages.eligibilityTitle}:
            </Typography>
            <Typography
              variant="h4"
              className={classes["eligibility-headline"]}
            >
              {messages.diseaseTitle}
            </Typography>
            <Typography variant="p" className={classes["eligibility-label"]}>
              {messages.diseaseDescription}
            </Typography>
            <Typography
              variant="h4"
              className={classes["eligibility-headline"]}
            >
              {messages.failureTitle}
            </Typography>
            <Typography variant="p" className={classes["eligibility-label"]}>
              {messages.failureDescription}
            </Typography>
            <Typography
              variant="h4"
              className={classes["eligibility-headline"]}
            >
              {messages.surgeryTitle}
            </Typography>
            <Typography variant="p" className={classes["eligibility-label"]}>
              {messages.surgeryDescription}
            </Typography>
            <Typography
              variant="h4"
              className={classes["eligibility-headline"]}
            >
              {messages.restrictionTitle}
            </Typography>
            <Typography variant="p" className={classes["eligibility-label"]}>
              {messages.restrictionDescription}
            </Typography>
            <Typography
              variant="h4"
              className={classes["eligibility-headline"]}
            >
              {messages.dialysisTitle}
            </Typography>
            <Typography variant="p" className={classes["eligibility-label"]}>
              {messages.dialysisDescription}
            </Typography>
          </div>
        </Grid> */}
      </Grid>
      <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <ErrorModal
            handleClose={handleClose}
            open={open.open}
            target={open.target}
          />
        </Modal>
      </div>
    </Shell>
  );
};

export default Register;
