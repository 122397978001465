const trademark = String.fromCodePoint(0x2122);
const copy = {
  enrollment: {
    title: {
      employee: 'KardiaComplete Enrollment',
      provider: 'KardiaComplete Enrollment',
      dependent: 'KardiaComplete Enrollment'
    },
    subtitle: {
      employee: `Take control of your heart health with KardiaComplete.`,
      provider: `Take control of your heart health with KardiaComplete.`,
      dependent: 'Take control of your heart health with KardiaComplete.'
    },
    enrollButton: {
      '*': 'enroll'
    },
    dependentButton: {
      '*': '+ invite another dependent'
    },
    form: {
      title: {
        employee: 'enrollment information',
        provider: 'invite a dependent',
        dependent: 'enrollment information'
      },
      subtitle: {
        provider: 'Please fill out the required fields below including your dependent’s email so that they can be invited to complete enrollment. You can add up to 3 dependents.'
      },
      allFieldsRequired: {
        '*': '* Required'
      },
      firstName: {
        employee: 'first name',
        provider: 'your first name',
        dependent: 'first name'
      },
      lastName: {
        employee: 'last name',
        provider: 'your last name',
        dependent: 'last name'
      },
      dateOfBirth: {
        '*': 'date of birth'
      },
      email: {
        employee: 'email',
        provider: 'your email',
        dependent: 'email'
      },
      dependentEmail: {
        '*': 'dependent ${tokens[0]} email'
      },
      removeDependent: {
        '*': 'remove dependent'
      },
      phone: {
        '*': 'phone number'
      },
      state: {
        '*': 'state'
      },
      consent: {
        '*': 'I agree to the Terms of Service and Privacy Policy'
      },
      marketing: {
        '*': 'I consent to receive marketing communications from AliveCor about products and services. I can opt out at anytime, as described in the Privacy Policy.'
      },
      atrial: {
        '*': 'I have Atrial Fibrillation'
      },
      hypertension: {
        '*': 'I have Hypertension'
      },
      submitButton: {
        '*': 'SUBMIT'
      },
      enrollButton: {
        '*': 'SUBMIT'
      }
    },
    eligibility: {
      title: {
        employee: 'You may not be eligible to enroll if you have experienced any of the following conditions',
        provider: 'Your dependent may not be eligible to enroll if they have experienced any of the following conditions',
        dependent: 'You may not be eligible to enroll if you have experienced any of the following conditions'
      },
      diseaseTitle: {
        '*': 'Unstable Heart Disease'
      },
      diseaseDescription: {
        '*': 'In hospital, or hospitalized for heart condition or stroke within the last six months.'
      },
      failureTitle: {
        '*': 'Symptomatic Heart Failure'
      },
      failureDescription: {
        '*': 'Congestive heart failure with frequent symptoms or exacerbations'
      },
      surgeryTitle: {
        '*': 'Recent Heart Surgery'
      },
      surgeryDescription: {
        '*': 'Heart surgery in the last six months'
      },
      restrictionTitle: {
        '*': 'Anything Restricting Use of BP cuff or EKG'
      },
      restrictionDescription: {
        '*': 'Issues with using Heart Health Kit'
      },
      dialysisTitle: {
        '*': 'Dialysis'
      },
      dialysisDescription: {
        '*': 'Currently undergoing dialysis'
      }
    }
  }
}

const interpolate = function(replacements) {
  return new Function('tokens', `return \`${this}\``)(replacements);
}

const getPath = path => {
  const segments = path.split('.');
  let newPath = copy;
  let pathString = '';
  segments.forEach(e => {
    pathString = `${pathString}.${e}`;
    if(newPath[e]) newPath = newPath[e];
    else if(newPath['*']) newPath = newPath['*'];
    else newPath = null;
  });
  return newPath;
}

const getCopy = (path, replacements, fallback) => {
  const text = getPath(path);
  if(!text && typeof fallback !== 'string') throw new Error(`Path not found: ${path}`);
  return interpolate.call(text || fallback, replacements);
}

export default getCopy;
