import React from "react";
import { Fade, Box, Typography } from "@mui/material";
import PlayStoreImage from "../../assets/img/google-play-badge.png";
import AppleImage from "../../assets/img/Apple_Logo.svg";
import WarningImage from "../../assets/img/Vector.png";
import CloseIcon from "@mui/icons-material/Close";
import classes from "./index.module.css";
import { SPONSOR_CVS, SPONSOR_HP } from "constants/app";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1.5,
  border: "none !important",
  borderRadius: 2,
};

export const ErrorModal = (props) => {
  return (
    <Fade in={props.open}>
      <Box sx={style}>
        <div className={classes.crossStyle}>
          <CloseIcon
            style={{
              background: "#F3F5FA",
              padding: "0.25rem",
              borderRadius: 5,
              cursor: "pointer",
              color: "#174E8C",
            }}
            onClick={props.handleClose}
          />
        </div>
        <div className={classes.modalStyle}>
          <img
            src={WarningImage}
            style={{ height: 44, width: 50, marginBottom: "1rem" }}
          />
          <Typography
            id="transition-modal-title"
            variant="h5"
            style={{
              fontFamily: "'DM Sans', sans-serif",
              fontWeight: 700,
              textAlign: "center",
              fontSize: 19,
              margin: "0.4rem auto",
            }}
          >
            {props.target === SPONSOR_HP
              ? `You've already enrolled in KardiaComplete.`
              : `You are not eligible user`}
          </Typography>
          <Typography
            id="transition-modal-title"
            variant="body1"
            style={{
              fontFamily: "'DM Sans', sans-serif",
              fontWeight: 400,
              textAlign: "center",
              fontSize: 13,
              marginBottom: props.target === SPONSOR_CVS && "2rem",
            }}
          >
            {props.target === SPONSOR_HP
              ? `Follow the link below for your respective device to download the KardiaComplete App.`
              : `Please contact your employer.`}
          </Typography>
          {props.target === SPONSOR_HP && (
            <>
              <Typography
                id="transition-modal-title"
                variant="body1"
                style={{
                  fontFamily: "'DM Sans', sans-serif",
                  fontWeight: 400,
                  textAlign: "center",
                  fontSize: 13,
                }}
              >
                Then log in to begin enjoying your KardiaComplete experience.
              </Typography>
              <div
                style={{
                  margin: "1.5rem auto",
                  textAlign: "center",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <img src={PlayStoreImage} /> <img src={AppleImage} />
              </div>
            </>
          )}
          {/*   <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography>  */}
        </div>
      </Box>
    </Fade>
  );
};
